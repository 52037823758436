<template>
  <div class="advance">
    <div class="minw homeDivCss">
      <div class="bottom">
        <!-- 筛选条件 -->
        <div class="condition">
          <div class="searchTitle searchTitleLater">
            年份
          </div>
          <div style="flex: 47">
            <span v-for="(item, index) in yearList" @click="yearchange(item)"
              :class="{ active: queryParams.particularYear == item }">{{
              item }}</span>
          </div>
        </div>
        <div class="condition">
          <div class="searchTitle searchTitleLater">
            选科
          </div>
          <div style="flex: 47">
            <span v-for="(item, index) in subjectList" @click="subjectchange(item)"
              :class="{ active: queryParams.subjects == item }">{{
              item }}</span>
          </div>
        </div>
        <div class="condition">
          <div class="searchTitle searchTitleLater">
            批次
          </div>
          <div style="flex: 47">
            <span v-for="(item, index) in BatchlineType" @click="batchchange(item)"
              :class="{ active: queryParams.batchFile == item }">{{
              item }}</span>
          </div>
        </div>
        <div class="condition">
          <div class="searchTitle">
            共匹配到 <span style="color: #12B098;">{{ queryParams.total }}</span> 条结果
          </div>
          <div class="searchInput" style="width: 900px;">
            <el-input v-model="queryParams.searchmodel" @keyup.enter.native="sousuo" placeholder="请输入内容"></el-input>
            <div class="divicon" @click="sousuo('search')">
              <i class="el-icon-search" style="color: white;"></i>
            </div>
          </div>
           <div class="resetBtnText" @click="sousuo('reset')" style="margin: 2px 0 0 10px">重置</div>
        </div>

        <div class="faz">
          <el-table :data="tableData"  class="threeTable"
            :header-cell-style="{
              backgroundColor: '#f4f4f4',
              color: 'black',
              fontSize: '17px',
              textAlign: 'center',

            }" :cell-style="{
              textAlign: 'center',

            }" v-loading="loading" :row-class-name="tableRowClassName">
            <!-- <el-table-column prop="scorePlansEntity.particularYear" label="年份" width="60"></el-table-column> -->
            <el-table-column prop="scorePlansEntity.collegeCode" label="院校代号" width="80"></el-table-column>
            <el-table-column prop="scorePlansEntity.collegeName" :show-overflow-tooltip="true" label="院校名称"  width="160"></el-table-column>
            <el-table-column prop="scorePlansEntity.majorCode" label="专业代号" width="80"></el-table-column>
            <el-table-column prop="scorePlansEntity.majorname" :show-overflow-tooltip="true"  label="专业名称"  width="160"></el-table-column>
            <el-table-column prop="scorePlansEntity.professionalInstruction" label="专业简介"></el-table-column>
            <el-table-column prop="scorePlansEntity.recruitStudentNumber" label="招生人数" width="80"></el-table-column>
            <el-table-column prop="scorePlansEntity.professionalSystem" label="学制" width="60"></el-table-column>
            <el-table-column prop="scorePlansEntity.tuition" label="学费" width="70"></el-table-column>
            <el-table-column prop="scorePlansEntity.score" label="最低分" width="70"></el-table-column>
            <el-table-column prop="scorePlansEntity.ranking" label="位次" width="70"></el-table-column>
          </el-table>
          <pagination v-show="queryParams.total > 0" :total="queryParams.total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="onload()" />

        </div>
        <!-- <div
          v-for="(item, index) in tableData"
          class="XiaoXibox"
          :key="index"
          @click="godetail(item)"
        >
          <div :style="{ color: index == 0 ? '#30C9B2' : '' }">
            <p></p>
            <h2>{{ item.titleMain }}</h2>
            <h3>{{ item.titleFu }}</h3>
          </div>
          <span>{{ item.time }}</span>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { REGlistPage, queryTqp } from "@/api/homeIndex.js"
export default {
  name: "advance",
  data() {
    return {
      loading: true,
      tableData: [

      ],
      queryParams: {
        total: 0,
        pageNum: 1,
        pageSize: 50,
        province: '河北',
        particularYear: new Date().getFullYear()-1,
        batchFile: '本科提前批B段',
        subjects: '',
        socre: '',
        majorsocre: '',
        searchmodel: '',
      },
      BatchlineType: [
        '本科提前批A段',
        '本科提前批B段',
        '本科提前批C段',
        '专科提前批',
      ],
      yearList: [],
      subjectList: ['物理', '历史',]
    };
  },
  created() {
    for (let index = 0; index < 3; index++) {
      let item = new Date().getFullYear() - index
      this.yearList.push(item)
    }
    let serverTime = new Date(this.$store.state.serverTime)
    // 系统时间小于6.25展示去年，反之展示今年。
    // if ( serverTime.getMonth() < 5) {
    //   this.queryParams.particularYear =  serverTime.getFullYear() - 1;
    // }else if ( serverTime.getMonth() == 5){
    //   if ( serverTime.getDate() <= 25) {
    //     this.queryParams.particularYear = serverTime.getFullYear() - 1;
    //   } else {
    //     this.queryParams.particularYear =  serverTime.getFullYear()
    //   }
    // } else {
    //   this.queryParams.particularYear =  serverTime.getFullYear()
    // }
    this.onload()   //获取数据列表
    // this.getBatchType()     //获取批次类型
  },
  methods: {
    yearchange(item) {
      this.queryParams.particularYear = item;
      this.onload()
    },
    subjectchange(item) {
      this.queryParams.subjects = item
      this.onload()
    },
    batchchange(dictLabel) {
      this.queryParams.batchFile = dictLabel
      this.onload()
    },
    sousuo(type){
          if(type == 'reset'){
                this.queryParams = {
        total: 0,
        pageNum: 1,
        pageSize: 50,
        province: '河北',
        particularYear: new Date().getFullYear()-1,
        batchFile: '本科提前批B段',
        subjects: '',
        socre: '',
        majorsocre: '',
        searchmodel: '',
      }
          }
          this.queryParams.pageNum = 1;
          this.queryParams.pageSize = 50;
          this.onload()
    },
    tableRowClassName({ row, rowIndex }) {
      const arr = [0, 2, 4, 6, 8, 10]
      if (arr.includes(rowIndex)) {
        return 'oddTable';
      } else {
        return 'evenTable';
      }
      return '';
    },
    onload() {
      const self = this;
      self.loading = true;

      let obj = { ...this.queryParams }
      queryTqp(obj).then(res => {
        if (res.code == 200) {
          res.data.rows.forEach(item => {
            if (item.scorePlansEntity.score <=5 || !item.scorePlansEntity.score) {
              item.scorePlansEntity.score = '--'
            }

              for (const key in item.scorePlansEntity) {
                if (!item.scorePlansEntity[key]) {
                    item.scorePlansEntity[key] = '--'
                }
              }

          })

          self.tableData = res.data.rows;
          self.queryParams.total = res.data.total;

          self.loading = false
        }
      })
    },

    search() {
      this.queryParams.pageNum = 1
      this.queryParams.pageSize = 50;
      this.onload()
    },
    reset() {
      this.queryParams = {
        total: 0,
        pageNum: 1,
        pageSize: 10,
        province: '河北',
        year: '',
        batch: '',
        type: '',
        socre: '',
        majorsocre: '',
      }
      this.onload()
    },
    // godetail(item) {
    //   this.$router.push({
    //     path: "/batchDetail",
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.advance {
  user-select: none;

  // background: rgb(252, 251, 249);
  // padding-bottom: 100px;
  .homeDivCss .bottom {
    // height: 805px;
    overflow-y: scroll;
  }

  .homeDivCss .bottom::-webkit-scrollbar {
    display: none;
  }

  .bottom {

    // ::v-deep .el-table .oddTable{
    //   background: white;
    // }
    // ::v-deep .el-table .evenTable{
    //   background: #EEEEEE;
    // }
    .active {
      background: #12B098;
      border-radius: 8px;
      color: white;
    }

    .active:hover {
      color: white;
    }

    .XiaoXibox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      padding: 25px 20px;
      margin-bottom: 20px;
      cursor: pointer;
      font-size: 20px;

      div:nth-of-type(1):hover {
        color: #30C9B2;
      }

      p {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        display: inline-block;
        background: #30C9B2;
        margin-right: 10px;
      }

      h2 {
        display: inline-block;
      }
    }
  }
}

.faz {
  position: relative;
}

.zz {
  width: 100%;
  height: 600px;
  background-color: #000000a2;
  text-align: center;
  color: red;
  font-size: 40px;
}
</style>
